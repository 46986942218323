<template>
  <div class="loginPage">
    <div class="logonBox" v-if="showPage">
      <div class="loginLeft"><img src="./img/loginleft.png" /></div>
      <div class="loginRight">
        <div class="loginBox">
          <el-row>
            <el-col :span="24">
<!--              <div class="logo"><img src="./../../assets/logo.png" alt="logo" /></div>-->
              <div class="logo">{{ $t('maintenanceSystem') }}</div>
<!--              <h2>{{ $t('loginWelcome') }}</h2>-->
            </el-col>
          </el-row>
           <el-row >
            <el-col :span="24">
              <el-form class="loginForm">
                <el-form-item>
                  <el-input :placeholder="$t('account')" v-model="formData.loginName" @input="changValue" @keyup.enter.native="userLogin" >
                    <img class="prefixIcon" slot="prefix" src="./img/user.png" :alt="$t('account')" />
                  </el-input>
                </el-form-item>
                <el-form-item class="formItembox">
                  <el-input :type="showPass ? 'text': 'password'" :placeholder="$t('password')" v-model="formData.password"  @input="changValue" @keyup.enter.native="userLogin" >
                  <img class="prefixIcon" slot="prefix" src="./img/lock.png" :alt="$t('password')" />

                  <i slot="suffix" :class="[showPass ? 'showpass' : 'hidepass']" autocomplete="auto" @click="showPass =! showPass">
                    <template v-if="showPass">
                       <img src="./img/passShow.png" />
                    </template>
                    <template v-else>
                      <img src="./img/passHide.png" />
                    </template>
                  </i>
                  </el-input>
                </el-form-item>
                <el-form-item class="formItembox">
                  <el-checkbox v-model="isSavePass">{{ $t('savePassWord') }}</el-checkbox>
                  <el-checkbox v-model="IsAutoLogin">{{ $t('autoLogin') }}</el-checkbox>
                </el-form-item>
                <el-form-item>
                  <el-button class="loginBnt" type="primary" @click="userLogin">{{$t('login') }}</el-button>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </div>
        <div class="footBox">
          <div class="footItem">
            <div :class="$utils.getLocalStorage('lang') === 'en' ? 'footApp' : 'footLink'">
              <el-link :underline="false" type="primary" @click="apkDownload">{{ $t('loginAppDownload') }}</el-link>
            </div>
            <div class="footLink">
<!--              <el-link :underline="false" href="http://www.tmva-sh.com" type="primary" target="_blank">{{ $t('contactUs') }}</el-link>-->
            </div>
            <div class="footRadio">
              <el-radio-group v-model="lang" size="mini" @change="changeLang">
                <el-radio-button label="zh">{{ $t('zh') }}</el-radio-button>
                <el-radio-button label="en">{{ $t('en') }}</el-radio-button>
                <el-radio-button label="ja">{{ $t('ja') }}</el-radio-button>
              </el-radio-group>
            </div>
          </div>
<!--          <div class="footCopy">{{ $t('copyright') }}</div>-->
        </div>
      </div>
    </div>
    <Loading v-if="showLoading"/>
    <div v-if="loadingFail" class="loadingFail"></div>
    <el-dialog
      :title="$t('loginAppDownload')"
      :visible.sync="apkDownloadDialogVisible"
      width="30%"
      custom-class="apkDownload"
      center
      @close="apkDownloadClose"
    >
      <div ref="apkDownload"></div>
      <br>
      <div>{{$t('downloadTip1')}}<a :href="downApkUrl" style="color: blue;">{{$t('downloadTip2')}}</a></div>
    </el-dialog>
  </div>
</template>

<script>
import Index from './_index.js'

export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>
