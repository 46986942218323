<template>
  <div class="topHeader" :class="{ active: isFold }">
    <!-- 折叠和展开 -->
    <div class="leftMenu" @click="updateIsFold">
      <img src="./img/fold_active.png" class="foldactive" v-if="isFold" alt="" :title="$t('Unfold')"/>
      <img src="./img/fold.png" class="foldactive " v-else alt="" :title="$t('Shrink')"/>
    </div>
    <div class="leftMenu" slot="reference" @click="getShowMessage" >
        <img src="./img/tixing.png"  class="foldactive" alt="" :title="$t('news')" />
        <span class="newMessage" v-show="$utils.getLocalStorage('ShowMessage' + userInfo.accountId)"></span>
      </div>
    <!-- 消息弹框 -->
    <el-dialog
      top="14vh"
      :modal="false"
      :visible.sync="showMessage"
      v-if="showMessage"
      :show-close="false"
      width="610px"
      v-model="showMessage"
      class="messageCss"
    >
      <div class="messageContent_1">
        <ul class="messageContent_title">
          <li
            :class="{ active: messageType === 'ALL' }"
            @click="changeMessageType('ALL')"
          >
            <img src="./img/suoyou.png" />
            <span>{{ $t('msgAll') }}</span>
          </li>
          <li
            :class="{ active: messageType === 'hujiao' }"
            @click="changeMessageType('hujiao')"
          >
            <img src="./img/hujiao.png" />
            <span>{{ $t('msgCall') }}</span>
          </li>
          <!-- 预约 -->
          <li
            :class="{ active: messageType === 'yuyue' }"
            @click="changeMessageType('yuyue')"
          >
            <img src="./img/yuyue.png" />
            <span :class="{applyText: $utils.getLocalStorage('lang') === 'en'}">{{ $t('msgAppointment') }}</span>
          </li>
          <li
            :class="{ active: messageType === 'shenqing' }"
            @click="changeMessageType('shenqing')"
          >
            <img src="./img/shenqing.png" />
            <span>{{ $t('msgApply') }}</span>
          </li>
          <li
            :class="{ active: messageType === 'xitong' }"
            @click="changeMessageType('xitong')"
          >
            <img src="./img/xitongxiaoxi.png" />
            <span>{{ $t('msgSystem') }}</span>
          </li>
          <li
            :class="{ active: messageType === 'chuanshu' }"
            @click="changeMessageType('chuanshu')"
          >
            <img src="./img/chuanshu.png" />
            <span>{{ $t('msgUpload') }}</span>
          </li>
        </ul>
        <div class="messageContent_inner" id="messageContent_inner">
          <div class="sysMessageLists">
            <template v-for="(item, index) in messageContent">
              <!-- 呼叫 -->
              <div
                class="list"
                :key="index"
                v-if="item.type === 'INVITE_INTO_ROOM'"
              >
                <h2 style="border-left-color: #72DE00">
                  <template v-if="user.accountUid === item.targetUserAccountData.userAccountUid">
                   <div class="titleBoxName">{{ $t('msgCallIn') }}</div>
                   <div class="titleBoxPic"><img src="./img/huru.png"/></div>
                  </template>
                  <template v-else>
                   <div class="titleBoxName">{{ $t('msgCallOut') }}</div>
                   <div class="titleBoxPic"><img src="./img/huchu.png"/></div>
                  </template>
                </h2>
                <el-row class="listInner">
                  <template v-if="user.accountUid === item.targetUserAccountData.userAccountUid">
                    <el-col :span="11">{{item.userAccountData.name }}-{{item.userAccountData.accountId }}</el-col>
                  </template>
                  <template v-else>
                    <el-col :span="11">{{item.targetUserAccountData.name }}-{{item.targetUserAccountData.accountId }}</el-col>
                  </template>
                  <el-col :span="5" >
                    <!-- 我邀请别人 -->
                    <template v-if="item.createByUid === user.accountUid">
                      {{ inviteToRoomResultA[item.inviteToRoomResult] }}
                    </template>
                    <!-- 别人邀请我 -->
                    <template v-else>
                      {{ inviteToRoomResultB[item.inviteToRoomResult] }}
                    </template>
                  </el-col>
                  <el-col :span="8" style="text-align: right;">
                    {{ $utils.format(item.createTime, 'yyyy-MM-dd hh:mm:ss') }}
                    <div class="btnYuyue">
                      <el-button type="primary" size="small"  @click="invite(item)" >{{ $t('msgCall') }}</el-button>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <!-- 申请 -->
              <div
                class="list"
                :key="index"
                v-else-if="item.type === 'ADD_CONTACTS'"
              >

                <h2 style="border-left-color: #0B81E0">
                  <template v-if="user.accountUid === item.targetUserAccountData.userAccountUid">
                   <div class="titleBoxName">{{ $t('msgApply') }}</div>
                   <div class="titleBoxPic"><img src="./img/huru.png"/></div>
                  </template>
                  <template v-else>
                   <div class="titleBoxName">{{ $t('msgApply') }}</div>
                   <div class="titleBoxPic"><img src="./img/huchu.png"/></div>
                  </template>
                </h2>
                <el-row class="listInner">
                  <el-col :span="12">{{
                    item.createByUid === user.accountUid  ? item.targetUserAccountData.companyName  : item.userAccountData.companyName
                  }}</el-col>
                  <el-col :span="6">{{
                    item.createByUid === user.accountUid   ? item.targetUserAccountData.groupName  : item.userAccountData.groupName
                  }}</el-col>
                  <el-col :span="6" class="personright">{{
                    item.createByUid === user.accountUid   ? item.targetUserAccountData.accountId  : item.userAccountData.accountId  }}
                     <template
                      v-if="
                      item.addContactsStatus === 'APPLIED' &&
                      item.createByUid !== user.accountUid">
                      <div class="fileBtnRedD">
                        <el-button
                          type="primary"
                          size="small"
                          @click="userAccountConfirmAddContacts(item, 'PASS')"
                          >{{ $t('msgAgree') }}</el-button
                        >
                      </div>
                      <div class="fileBtndel">
                        <el-button
                          type="warning"
                          size="small"
                          @click="
                            userAccountConfirmAddContacts(item, 'REJECTED')
                          "
                          >{{ $t('msgRefuse') }}</el-button
                        >
                      </div>
                    </template>
                    <template v-else>
                      <div class="fileBtnText">{{ addContactsStatus[item.addContactsStatus] }}</div>
                    </template>
                    </el-col>
                  <el-col :span="6">
                  </el-col>
                </el-row>
              </div>
              <!-- 系统 -->
              <div
                class="list"
                :key="index"
                v-else-if="item.type === 'SYSTEM_NOTICE'"
              >
                <h2 style="border-left-color: #FFA919">
                  {{ $t('msgSystem') }}
                </h2>
                <h3 class="xtTitle">
                  {{ item.title }}
                </h3>
                <el-row class="listInner" style="padding-top: 5px">
                  <el-col :span="24" class="xtContent">{{ item.content }}</el-col>
                  <div class="xtTimes">{{$utils.format(item.createTime, 'yyyy-MM-dd hh:mm:ss')}}</div>
                </el-row>
              </div>
              <!--传输-->
               <div class="list" :key="index" v-else>
                 <h2 style="border-left-color: #1AC49C">
                  <template v-if="user.accountUid === item.receiverUserAccount?.userAccountUid">
                   <div class="titleBoxName">{{ $t('fileAccept') }}</div>
                   <div class="titleBoxPic"><img src="./img/huru.png"/></div>
                  </template>
                  <template v-else>
                   <div class="titleBoxName">{{ $t('fileSend') }}</div>
                   <div class="titleBoxPic"><img src="./img/huchu.png"/></div>
                  </template>
                </h2>
                  <el-row>
                    <el-col :span="24">
                      <div class="fileNameTitle">{{item.fileName}}</div>
                      <template class="fileHandle" v-if="item.isBackOut ==='YES'">
                          <div class="fileBtnText">{{ $t('fileRecall')}}</div>
                      </template>
                      <template class="fileHandle" v-if="item.isBackOut ==='NO'">
                        <template v-if="user.accountUid === item.userAccountId">
                          <template v-if="item.isRead ==='YES'">
                            <div class="fileBtnRedTxt">{{ $t('fileReread') }}</div>
                            <div class="fileBtnRed">
                            <el-button type="primary" size="small"  @click="getKkFileViewForPushFile(item.pushFileRecordId)" >{{ $t('uploadPreview') }}</el-button>
                          </div>
                          </template>
                          <template v-if="item.isRead ==='NO'">
                            <div class="fileBtnBack" v-if="item.isBackOut ==='NO'"  >
                              <el-button type="primary" size="small" @click="cancelPushFile(item.pushFileRecordId)" >{{ $t('uploadBackOut') }}</el-button>
                            </div>
                            <div class="fileBtnRedD">
                              <el-button type="primary" size="small"  @click="getKkFileViewForPushFile(item.pushFileRecordId)" >{{ $t('uploadPreview') }}</el-button>
                            </div>
                          </template>
                        </template>
                        <template v-if="user.accountUid !== item.userAccountId">
                          <div class="fileBtndel">
                            <el-button type="primary" size="small"  @click="delPushFileRecord(item.pushFileRecordId)" >{{ $t('delete') }}</el-button>
                          </div>
                          <div class="fileBtnRedD">
                            <el-button type="primary" size="small"  @click="getKkFileViewForPushFile(item.pushFileRecordId)" >{{ $t('uploadPreview') }}</el-button>
                          </div>
                        </template>
                      </template>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="6">
                        <p v-if="user.accountUid === item.receiverUserAccount?.userAccountUid" class="fileUserName">
                          {{item.userAccountName}}
                        </p>
                        <p v-else class="fileUserName">
                          {{item.receiverUserAccount?.name}}
                        </p>
                    </el-col>
                    <el-col :span="3">
                      <p v-if="user.accountUid === item.receiverUserAccount?.userAccountUid" class="fileUserName">
                        {{item.userAccountGroup}}
                      </p>
                      <p v-else class="fileUserName">
                        {{item.receiverUserAccount?.groupName}}
                      </p>
                    </el-col>
                    <el-col :span="8">
                      <p v-if="user.accountUid === item.receiverUserAccount?.userAccountUid" class="fileUserName">
                        {{item.accountId}}
                      </p>
                      <p v-else class="fileUserName">
                        {{item.receiverUserAccount?.accountId}}
                      </p>
                    </el-col>
                    <el-col :span="7">{{$utils.format(item.pushTime,'yyyy-MM-dd hh:mm:ss')}}</el-col>
                  </el-row>
              </div>
            </template>
           <div v-if="!messageContent.length && isAllLoaded && !showMeeting && !showUploadFile"
              style="font-size: 20px;text-align: center; margin-top: 160px;color: #999;">
              {{ $t('fileNodata') }}
            </div>
            <!-- 预约 -->
            <template v-if="meetingDataList.length">
              <div class="list2" v-show="showMeeting" v-for="item in meetingDataList" :key="item.id">
                <h2 style="border-left-color: #FF9AA1">
                  <template v-if="user.accountUid === item.userAccountData.userAccountUid">
                   <div class="titleBoxName">{{ $t('startInvite') }}</div>
                   <div class="titleBoxPic"><img src="./img/huchu.png"/></div>
                  </template>
                  <template v-else>
                   <div class="titleBoxName">{{ $t('toBeInvited') }}</div>
                   <div class="titleBoxPic"><img src="./img/huru.png"/></div>
                  </template>
                </h2>
                <el-row class="listInner">
                    <el-col :span="7">
                      <div class="txtBord">{{$utils.format(item.appointmentTime,'yyyy年MM月dd日')}}</div>
                      </el-col>
                    <el-col :span="6"><div class="txtBord txtColor">{{$utils.replaceThree(item.roomIntegerNumber)}}</div></el-col>
                    <el-col :span="11"><div class="txtBord txtHidden">{{item.roomTheme}}</div>
                    <div class="personBox">{{ $t('startInvitePer') }}：{{item.userAccountData.accountId}}</div>
                    </el-col>
                  </el-row>
                  <el-row class="listbottom">
                    <el-col :span="8">{{ $t('startTimeGuide') }}：{{$utils.format(item.appointmentStartTime,'hh:mm')}}</el-col>
                    <el-col :span="8">{{ $t('enterTimeGuide') }}：{{$utils.format(item.enterTime,'hh:mm')}}</el-col>
                    <el-col :span="8"><div class="personright">{{ $t('quitTimeGuide') }}：{{$utils.format(item.leaveTime,'hh:mm')}}</div>
                    </el-col>
                  </el-row>
              </div>
            </template>
            <template v-if="meetingDataList.length === 0">
              <div v-if="showMeeting" style="font-size: 20px;text-align: center; margin-top: 160px;color: #999;">{{ $t('fileNodata') }}</div>
            </template>

          </div>
        </div>
      </div>
      <div class="messageContentFoot">{{$t('showDays')}}</div>
    </el-dialog>
    <!-- 右侧操作菜单 -->
    <div class="rightOptions">
<!--      <div class="list"-->
<!--      :class="{ active: showMenuEquiment }"-->
<!--      @click="openMenuEquiment">-->
<!--        <span class="icon device" />-->
<!--        &lt;!&ndash; lang:设备信息 &ndash;&gt;-->
<!--        <p>{{ $t('equipmentInfo') }}</p>-->
<!--      </div>-->
<!--      <div class="list"-->
<!--      :class="{ active: showCloudRecord }"-->
<!--       @click="openCloudRecord">-->
<!--        <span class="icon yun" />-->
<!--        <p>{{ $t('recordFile') }}</p>-->
<!--      </div>-->
      <div class="list topdropdown"
       :class="{ active: setDialogVisible || setHeadImgVisible || setNicknameVisible || setLayoutVisible || setDialogDevices}"  @click.stop="gettopdropdownSet"
      >
        <span class="icon set"></span>
        <p>{{ $t('set') }}</p>
        <div class="topdropdowncontent" v-show="topdropdownSet" ref="topdropdowncontent">
          <div class="dropdownItem" @click="openHeadimg"><span>{{ $t('downHeadImg') }}</span></div>
          <div class="dropdownItem" @click="openNickname"><span>{{ $t('downNickName') }}</span></div>
          <div class="dropdownItem" @click="openSetDevices"><span>{{ $t('setAudioCall') }}</span></div>
        </div>
      </div>
<!--      <div class="list topdropdown"-->
<!--         @click.stop="gettopdropdownHelp"-->
<!--      >-->
<!--        <span class="icon help"></span>-->
<!--        <p>{{ $t('help') }}</p>-->
<!--        <div class="topdropdowncontent" v-show="topdropdownHelp" ref="helpdropdowncontent">-->
<!--          <div class="dropdownItem" @click="getfileDownload"><span>{{ $t('appDownload') }}</span></div>-->
<!--          <div class="dropdownItem" @click="getIntroduction"><span>{{ $t('SystemIntroduction') }}</span></div>-->
<!--          <div class="dropdownItem" @click="getInstructions"><span>{{ $t('Instructions') }}</span></div>-->
<!--          <div class="dropdownItem" @click="openServiceSupport"><span>{{ $t('serviceSupport') }}</span></div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="headFace">
        <div style="margin: 0 10px">
          <div class="headImgBj">
              <div
                class="addHeadImgTo"
                v-if="!user.headImg"
              ></div>
              <el-image
                v-else
                class="headLogo"
                :src="user.headImg"
                fit="contain"
              ></el-image>
          </div>
        </div>
        <div>
          <div class="userName">
            <div>
              <div class="name dots">
                {{ userInfo.accountId }}
              </div>
              <div class="group dots">{{ userInfo.companyName }}</div>
            </div>
            <div>
              <div class="logout">
                  <div class="el-dropdown-link"  @click.stop="getlogoutdownShow">
                    <i v-if="loginToken" style="opacity: 0"  class="el-icon-arrow-down el-icon--right"
                    />
                    <i v-else class="el-icon-arrow-down el-icon--right" />
                  </div>
                  <div class="logoutdown" ref="logoutdown" v-show="logoutdownShow" @click="logout">
                    <p>{{ $t('logout') }}</p>
                  </div>
              </div>
              <!-- <el-dropdown :show-timeout="60" @command="logout" trigger="click">
                <div class="el-dropdown-link">
                  <i
                    v-if="loginToken"
                    style="opacity: 0"
                    class="el-icon-arrow-down el-icon--right"
                  />
                  <i v-else class="el-icon-arrow-down el-icon--right" />
                </div>
                <el-dropdown-menu slot="dropdown" v-if="!loginToken">
                  <el-dropdown-item command="logout">{{
                    $t('logout')
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 昵称 -->
    <base-dialog
      v-if="setNicknameVisible"
      class="headImgDialog"
      :title="$t('setNickName')"
      height="auto"
      @close="closeNickName"
      @confirm="confirmNickName"
    >
      <div class="nickNameBox">
        <el-input
          type="text"
          :placeholder="$t('pleaseEnterNickname')"
          v-model.trim="nickNameDefault"
          maxlength="10"
          show-word-limit
        />
        <p>{{$t('setNickNamePattern')}}</P>
      </div>
    </base-dialog>
    <!-- 头像设置 -->
    <base-dialog
      ref="cameraOrAudioSet"
      v-model="setHeadImgVisible"
      v-if="setHeadImgVisible"
      :title="$t('setHeadImg')"
      class="headImgDialog"
      width="510px"
      height="auto"
      @close="closesetHeadImg"
      @confirm="confirmSetHeadImg"
    >
      <el-form label-width="90px" class="headpicBox">
        <el-form-item :label="`${$t('selectHeadImg')}：`">
          <el-input :placeholder="$t('selectOnePIc')" v-model="fileHeadImg" readonly="readonly">
            <template slot="append">
              <el-upload
                ref="uploadHeadPic"
                :action="$api.fileUploadUserAccountHeadImg"
                :headers="getHeader()"
                :on-success="changeHeagImg"
                :beforeUpload="beforeUploadImg"
                :show-file-list="false"
                accept="image/*"
                >
                <el-button slot="trigger" size="small" type="primary">{{$t('fileName')}}</el-button>
              </el-upload>
            </template>
          </el-input>
        </el-form-item>
      <div class="headpicTxt">{{$t('selectHeadImgTxt')}}，{{$t('selectOnePicSize')}}</div>
      </el-form>
    </base-dialog>
    <!--相机和麦克风设置弹框-->
    <base-dialog
      ref="cameraOrAudioSet"
      v-model="setDialogVisible"
      v-if="setDialogVisible"
      :title="$t('setAudioDevices')"
      width="476px"
      height="auto"
      class="cameraDialog"
      @close="closeSetDialog"
      @confirm="confirmSetDialog"
    >
      <el-form class="setInner">
        <el-form-item label-width="68px" :label="`${$t('camera')}：`">
          <el-select
            :placeholder="$t('cameraPlaceholder')"
            v-model="currentCamIdCopy"
          >
            <el-option
              v-for="item in camDevs"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="`${$t('microphone')}：`">
          <el-select
            :placeholder="$t('microphonePlaceholder')"
            v-model="currentMicIdCopy"
          >
            <el-option
              v-for="item in micDevs"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </base-dialog>
    <!--音视频-->
    <base-dialog
      ref="cameraOrAudioSet"
      top="8vh"
      v-model="setDialogDevices"
      v-if="setDialogDevices"
      :title="$t('setAudioCall')"
      width="476px"
      height="auto"
      class="AudioSetDialog"
      :showFooter="false"
      :closeClickModal="false"
      :closePressEscape="false"
      @close="closeSetDevices"
    >
    <div class="setDevicesBox">
      <micro-phone
      :hstRtcEngine="hstRtcEngine"
      :devicesList="devicesList"
      @changgeCamere="changgeCamere"
      @changgeAudio="changgeAudio"
      :user="user"
      ></micro-phone>
    </div>
    </base-dialog>
    <!--服务支持弹框-->
    <base-dialog
      ref="serviceSupport"
      v-model="helpDialogVisible"
      :visible.sync="helpDialogVisible"
      v-if="helpDialogVisible"
      :title="$t('serviceSupport')"
      :show-footer="false"
      class="serviceSupportDialog"
      width="670px"
      height="auto"
      @close="closeHelpDialog"
    >
    <div class="helpInnerBox">
      <div class="versionCode">{{ $t('versionCode') }}</div>
      <div class="helpInnerLeft">
        <img src="./../../assets/logo2.png" class="imgBox" alt="" />
      </div>
      <div class="helpInnerRight">
        <p>{{ $t('companyName') }}</p>
        <p>{{ $t('phone') }}</p>
        <p>{{ $t('email') }}</p>
        <p>{{ $t('website') }}</p>
        <p>{{ $t('address') }}</p>
        <p>{{ $t('copyright') }}</p>
      </div>
    </div>
    </base-dialog>
    <!-- 设备信息展示 -->
    <base-dialog
      ref="equimentBoxKKK"
      class="equimentBoxKKK"
      v-if="showMenuEquiment"
      :title="$t('equipmentInfo')"
      width="1250px"
      height="600px"
      :show-footer="false"
      @close="showMenuEquiment = false"
    >
      <iframe class="equimentIframe" :src="showMenuEquimentSrc"></iframe>
    </base-dialog>
    <!-- 云录制文件 -->
    <base-dialog
      ref="cloudRecord"
      class="cloudRecord"
      v-if="showCloudRecord"
      :title="$t('recordFileBox')"
      width="710px"
      height="400px"
      :show-footer="false"
      @close="showCloudRecord = false"
    >
      <template v-if="totalElements">
        <ul class="cloudRecordLists">
          <li class="cloudRecordList" v-for="item in content" :key="item.id">
            <div class="cloudRecordContent">
              <el-image
                style="width: 150px; height: 100px;cursor: pointer;"
                :src="require('./img/yunRecordBg.png')"
                fit="cover"
              @click="cloudRecordRead(item)" />
              <div class="play"  @click="cloudRecordRead(item)" >
                <img :src="require('./img/play.png')"/>
              </div>
              <span class="time">{{ item.recordTime }}</span>
              <div :class="lang === 'zh' ? 'cloudRecordOpt' : 'cloudRecordOptEn' ">
                <template v-if="item.recordStatus === 6">
                  <div @click="downloadRecord(item)" >
                    <i class="el-icon-download"></i
                    ><template v-if="lang !== 'ja'">{{
                      $t('download')
                    }}</template>
                  </div>
                  <div @click="delRecord(item)" :class="lang === 'zh' ? '' : 'deleteOptEn' ">
                    <i class="el-icon-delete"></i>{{ $t('delete') }}
                  </div>
                </template>
                <div v-else>{{ recordStatus[item.recordStatus] }}</div>
              </div>
            </div>
            <p>{{ $utils.format(item.createTime, 'yyyy-MM-dd hh:mm:ss') }}</p>
            <p>{{ item.updateBy }}{{ $t('record') }}</p>
          </li>
        </ul>
        <div style="padding-top: 20px; text-align: center; position: absolute; left:300px;bottom:20px">
          <el-pagination
            ref="recordPagination"
            :page-size="pageSize"
            :current-page="pageNumber"
            background
            layout="prev, pager, next"
            :total="totalElements"
            @current-change="currentChange"
          />
        </div>
      </template>
      <template v-else>
        <div class="noDataMkl">{{ $t('noData') }}</div>
      </template>
    </base-dialog>
    <!-- 在线观看 -->
      <el-dialog
      width="900px"
      top="8vh"
      class="showcloudRecordRead"
      :title="$t('preview')"
      v-if="showcloudRecordRead"
      :visible.sync="showcloudRecordRead"
      :show-close="true"
      @close="showcloudRecordRead = false"
      :append-to-body="false"
    >
    <video autoplay ref="audio" controls :src="cloudRecordReadUrl" width="860px"></video>
    </el-dialog>

  <!-- 文件浏览 -->
    <el-dialog
      width="80%"
      top="8vh"
      ref="uploadFileBox"
      class="uploadFileBoxRead"
      v-if="showReadFile"
      :visible.sync="showReadFile"
      v-model="showReadFile"
      :show-close="true"
      @close="showReadFile = false"
      :append-to-body="false"
    >
    <div class="upload_heard">{{showFileName}} {{$t('showReadFile')}}
      <div class="uploadBtnBox">
        <div class="uploadBtnIcon" id="downLoad" :title="$t('hitdownload')" @click="uploadLink(uploadFileData)">
        </div>
      </div>
    </div>
    <el-progress class="progress" v-if="progressFlag"  :stroke-width="5" :percentage="progressPercent"></el-progress>
    <iframe class="uploadLink" :src="uploadFileShow"></iframe>
    </el-dialog>
  </div>
</template>

<script>
import microPhone from "../../components/microPhone";
import * as imageConversion from 'image-conversion';
import Bus from '../../helpers/bus'
import { getHeader } from "@/helpers/ajax";
import { getSessionStorage } from "@/helpers/utils";
import md5 from "js-md5";
import AgoraRTC from "agora-rtc-sdk-ng"

export default {
  components: {
    microPhone
  },
  props: {
    hstRtcEngine: {
      // 引擎实例
      type: Object,
      default: () => null,
    },
    mainVideoUserId: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      // 1:初始化,2:录制中,3:排队等待编码,4:编码中,5:上传中,6:完成,7未获取到状态,8 失效(系统自动清除),-1:任务异常 ,9 录制失败。
      // 云录制状态
      recordStatus: {
        1: this.$t('recordStatus1'),
        2: this.$t('recordStatus2'),
        3: this.$t('recordStatus3'),
        4: this.$t('recordStatus4'),
        5: this.$t('recordStatus5'),
        6: this.$t('recordStatus6'),
        7: this.$t('recordStatus7'),
        8: this.$t('recordStatus8'),
        9: this.$t('recordStatus9'),
        '-1': this.$t('recordStatus-1'),
      },
      // 消息管理
      showMessage: false,
      messageType: 'ALL', // 消息类型
      messageContent: [],
      addContactsStatus: {
        APPLIED: this.$t('APPLIED'),
        REJECTED: this.$t('REJECTED'),
        PASS: this.$t('PASS'),
      },
      isAllLoaded: false,
      // 我邀请别人
      inviteToRoomResultA: {
        TIMEOUT: this.$t('TIMEOUTA'), // 未接通
        AGREE: this.$t('AGREEA'), // 已接听
        REFUSE: this.$t('REJECTED'), // 拒绝接听
        CANCEL: this.$t('CANCELA'), // 未接通
        MESSAGE_RECEIVED: this.$t('CANCELA'),
        ALREADY_IN_ROOM: this.$t('CANCELA'), // 在会议
        NOT_SUPPORTED: this.$t('CANCELA'), // 不支持
        LOCKED: this.$t('CANCELA'), // 不支持
      },
      // 别人邀请我
      inviteToRoomResultB: {
        TIMEOUT: this.$t('TIMEOUTB'), // 未接来电
        AGREE: this.$t('AGREEB'), // 已接听
        REFUSE: this.$t('REFUSEB'), // 未接听
        CANCEL: this.$t('CANCELB'), // 未接来电
        MESSAGE_RECEIVED: this.$t('CANCELA'),
        ALREADY_IN_ROOM: this.$t('CANCELA'), // 在会议
        NOT_SUPPORTED: this.$t('CANCELA'), // 不支持
        LOCKED: this.$t('CANCELA'), // 不支持
      },
      // 服务单查询
      workOrderStatus: this.$dicts.workOrderStatus, // 服务单状态
      serviceDialogVisible: false, // 服务单查询弹框
      workOrderId: '', // 工单id
      attentionWorkOrderId: '', // 已关注工单id
      workOrderInfo: null, // 工单详情信息
      showUseTime: '', // 显示服务时长
      // 设备列表（包含相机、麦克风和扬声器） ---------------------------------
      camDevs: [], // 摄像头列表
      currentCamId: '', // 当前选择相机的设备id
      currentCamIdCopy: '', // 当前选择相机的设备id副本
      micDevs: [], // 麦克风列表
      currentMicId: '', // 当前选择麦克风的设备id
      currentMicIdCopy: '', // 当前选择麦克风的设备id副本
      spkDevs: [], // 扬声器列表
      currentSpkId: '', // 当前选择扬声器的设备id
      currentSpkIdCopy: '', // 当前选择扬声器的设备id副本
      setDialogVisible: false, // 设置弹框
      setHeadImgVisible: false, // 头像设置
      setNicknameVisible: false,
      setLayoutVisible: false,
      setDialogDevices: false,
      nickNameDefault: '',
      fileHeadImg: '',
      fileHeadImgArr: '',
      // 帮助
      helpDialogVisible: false, // 帮助弹框
      // 用户详细信息
      userInfo: {},
      // 设备信息
      showMenuEquiment: false,
      showMenuEquimentSrc: '',
      headers: getHeader(),
      // 云录制
      showCloudRecord: false,
      showcloudRecordRead: false,
      cloudRecordReadUrl: null,
      content: [], // 查询列表
      pageNumber: 1,
      pageSize: 8,
      totalElements: 0,
      loginToken: this.$utils.getSessionStorage('loginToken'),
      lang: this.$utils.getLocalStorage('lang'),
      // 预约
      showMeeting: false,
      meetingDataList: [], // 预约列表
      // yuyueCurrentPageData: [], // 当前页显示内容
      // yuyueCurrentPage: 1, // 当前页
      // yuyuePageSize: 10, //  每页的条数
      // yuyueDataPageTotal: 0, // 预约数据总页数
      // 文件传输
      uploadFileList: [], // 获取文件推送记录
      showUploadFile: false,
      isMettingReadFile: false, // 判断推送文件是否预览
      messageListFile: '',
      showmessageListFile: false,
      showReadFile: false,
      // 文件下载
      uploadFileData: '',
      uploadDownList: [],
      officeLink: '',
      uploadFileShow: '',
      showFileName: '',
      radioSelect: '1',
      voiceActive: "",
      audioContext: '',
      maxVal: '',
      devicesList: [],
      topdropdownSet: false,
      topdropdownHelp: false,
      logoutdownShow: false,
      progressPercent: 0,
      progressFlag: false,
      OEM_NAME: 'TMVA', // TMVA版本代号
      percentComplete: 0,
      isSHowPercent: false,
      rtcEngine: process.env.VUE_APP_RTC_ENGINE,
    }
  },
  computed: {
    // 用户信息
    user() {
      return this.$store.state.user
    },
    // 菜单是否折叠
    isFold() {
      return this.$store.state.isFold
    },
  },
  watch: {
    showMeeting: 'watchShowMeeting'
  },
  mounted() {
    Bus.$on('logoutdownShow', (content) => {
      this.logoutdownShow = content
    });
    Bus.$on('topdropdownSet', (content) => {
      this.topdropdownSet = content
    });
    Bus.$on('topdropdownHelp', (content) => {
      this.topdropdownHelp = content
    });

    document.addEventListener("click", (e) => {
      if (this.logoutdownShow === true) {
        this.closeLogOut(e)
      }
      if (this.topdropdownSet === true) {
        this.closeTopset(e)
      }
      if (this.topdropdownHelp === true) {
        this.closeTophelp(e)
      }
    });
    this.getMediaDevices()
    // 查询用户详情
    this.userAccountFindInfo()
  },
  methods: {
    getHeader() {
      const headers = {
        'software-version': getSessionStorage("SoftwareVersion"),
        'oem-name': getSessionStorage("OemName"),
        'device-type': getSessionStorage("DeviceType"),
      }
      const defaultHeader = {};
      const token = getSessionStorage("token");
      const version = getSessionStorage("version");
      if (token) defaultHeader.subject_token = token;
      if (version) defaultHeader.version = version;
      defaultHeader.api_version = 'V3';

      const dateDiff = new Date().getTime()
      const signStr = `sign=&timestamp=${dateDiff}&tag=tmva&deviceCode=${dateDiff}&device-type=WEB&software-version=${getSessionStorage("SoftwareVersion")}&oem-name=TMVA`
      const sign = md5(signStr)
      console.log('signStr', signStr, 'sign', sign)
      const deviceCode = dateDiff
      const timestamp = dateDiff
      defaultHeader.deviceCode = deviceCode
      defaultHeader.sign = sign
      defaultHeader.timestamp = timestamp

      return Object.assign(defaultHeader, headers);
    },
    getlogoutdownShow() {
      this.logoutdownShow = !this.logoutdownShow
      this.topdropdownSet = false
      this.topdropdownHelp = false
      Bus.$emit('leftdropdownBox', false)
    },
    gettopdropdownSet() {
      this.topdropdownSet = !this.topdropdownSet
      this.topdropdownHelp = false
      this.logoutdownShow = false
      Bus.$emit('leftdropdownBox', false)
    },
    gettopdropdownHelp() {
      this.topdropdownHelp = !this.topdropdownHelp
      this.topdropdownSet = false
      this.logoutdownShow = false
      Bus.$emit('leftdropdownBox', false)
    },
    changgeCamere(CamId) {
      this.$emit('confirmSet', CamId, this.currentMicId)
    },
    changgeAudio(MicId) {
      this.$emit('confirmSet', this.currentCamId, MicId)
    },
    closeLogOut(e) {
      const self = this;
      if (self.logoutdownShow === true) {
        const tree = self.$refs.logoutdown
        if (tree) {
          if (!tree.contains(e.target)) {
            self.logoutdownShow = false;
          }
        }
      }
    },
    closeTopset(e) {
      const self = this;
      if (self.topdropdownSet === true) {
        const tree = self.$refs.topdropdowncontent
        if (tree) {
          if (!tree.contains(e.target)) {
            self.topdropdownSet = false;
          }
        }
      }
    },
    closeTophelp(e) {
      const self = this;
      if (self.topdropdownHelp === true) {
        const tree = self.$refs.helpdropdowncontent
        if (tree) {
          if (!tree.contains(e.target)) {
            self.topdropdownHelp = false;
          }
        }
      }
    },
    invite(item) {
      this.showMessage = false
      const params = {}
      if (item.targetUserAccountData.accountId === this.user.accountId) {
        params.accountId = item.userAccountData.accountId
        params.id = item.userAccountData.userAccountUid
      } else {
        params.accountId = item.targetUserAccountData.accountId
        params.id = item.targetUserAccountData.userAccountUid
      }
      this.$emit('invite', params)
    },
    userAccountConfirmAddContacts(item, status) {
      this.$ajax({
        url: this.$api.userAccountConfirmAddContacts,
        data: {
          communicationRecordId: item.id,
          status,
        },
      }).then(() => {
        this.$parent.getOnlineCenterUser()
        item.addContactsStatus = status
        this.$emit('updateContacts')
      })
    },
    // 有新消息下发
    onRecvUserMsg(data) {
      // 传输
      if (data.type === 'PUSH_ROOM_FILE' ||
          data.type === 'PUSH_ROOM_FILE_STATE' ||
          data.type === 'PUSH_USER_ACCOUNT_FILE' ||
          data.type === 'PUSH_USER_ACCOUNT_FILE_STATE'
      ) {
        if (this.showMessage) {
          if (this.messageType === 'ALL') {
            this.messageReadMessage(data.body.messageId, () => {
              this.getAllMessage((data) => {
                this.messageContent = this.$utils.sortByKeyDesc(data, 'createTime')
              })
            })
          } else if (this.messageType === 'chuanshu') {
            this.messageReadMessage(data.body.messageId, () => {
              this.fileRecordFind({}, (data) => {
                const list = data.map(res => Object.assign({}, res, {
                  createTime: res.pushTime
                }))
                this.messageContent = this.$utils.sortByKeyDesc(list, 'createTime')
              })
            })
          }
        }
        setTimeout(() => {
          this.$utils.setLocalStorage('ShowMessage' + this.userInfo.accountId, this.userInfo.accountId)
          this.$forceUpdate();
        }, 1000)
        return
      }
      // 下发系统消息
      if (
        data.type === 'NEW_MESSAGE_NOTICE' ||
        data.type === 'MESSAGE_NOTICE_ROLLBACK'
      ) {
        this.$utils.setLocalStorage('ShowMessage' + this.userInfo.accountId, this.userInfo.accountId)
        this.$forceUpdate();
        this.$forceUpdate();
        if (this.showMessage) {
          if (this.messageType === 'ALL') {
            this.messageReadMessage(data.body.messageId, () => {
              this.getAllMessage((data) => {
                this.messageContent = this.$utils.sortByKeyDesc(data, 'createTime')
              })
            })
          } else if (this.messageType === 'xitong') {
            this.messageReadMessage(data.body.messageId, () => {
              this.messageFindMy({}, (data) => {
                this.messageContent = this.$utils.sortByKeyDesc(data, 'createTime')
              })
            })
          }
        }
        setTimeout(() => {
          this.$utils.setLocalStorage('ShowMessage' + this.userInfo.accountId, this.userInfo.accountId)
          this.$forceUpdate();
        }, 1000)
        return
      }
      // 下发申请消息
      if (
        data.type === 'APPLY_ADD_CONTACTS'
      ) {
        if (this.showMessage) {
          if (this.messageType === 'ALL') {
            this.messageReadMessage(data.body.messageId, () => {
              this.getAllMessage((data) => {
                this.messageContent = data
              })
            })
          } else if (this.messageType === 'shenqing') {
            this.messageReadMessage(data.body.messageId, () => {
              this.communicationRecordFind({ type: 'ADD_CONTACTS' }, (data) => {
                this.messageContent = data
              })
            })
          }
        }
        this.$parent.getOnlineCenterUser()
        setTimeout(() => {
          this.$utils.setLocalStorage('ShowMessage' + this.userInfo.accountId, this.userInfo.accountId)
          this.$forceUpdate();
        }, 1000)
        return
      }
      if (data.type === 'APPLY_ADD_CONTACTS_RESULT') {
        if (this.showMessage) {
          if (this.messageType === 'ALL') {
            this.messageReadMessage(data.body.messageId, () => {
              this.getAllMessage((data) => {
                this.messageContent = data
              })
            })
          } else if (this.messageType === 'shenqing') {
            this.messageReadMessage(data.body.messageId, () => {
              this.communicationRecordFind({ type: 'ADD_CONTACTS' }, (data) => {
                this.messageContent = data
              })
            })
          }
        }
        this.$parent.getOnlineCenterUser()
        this.$utils.setLocalStorage('ShowMessage' + this.userInfo.accountId, this.userInfo.accountId)
        this.$forceUpdate();
        return
      }

      // 呼叫
      if (
        data.type === 'INVITE_INTO_ROOM' ||
        data.type === 'CANCEL_INVITE_INTO_ROOM' ||
        data.type === 'PUB_INVITE_TO_ROOM_RESULT'
      ) {
        setTimeout(() => {
          this.$utils.setLocalStorage('ShowMessage' + this.userInfo.accountId, this.userInfo.accountId)
          this.$forceUpdate();
        }, 1000)
        return
      }
    },
    // 滚动到顶部
    scollToTop() {
      this.$nextTick(() => {
        document.getElementById('messageContent_inner').scrollTop = 0
      })
    },
    // 监听切换首次打开预约状态
    watchShowMeeting() {
      if (this.messageType !== 'yuyue') {
        this.showMeeting = false
      }
    },
    // 切换消息类型
    changeMessageType(type) {
      this.messageType = type
      // 全部
      if (type === 'ALL') {
        this.showMeeting = false
        this.getAllMessage((data) => {
          // console.log(data)
          this.messageContent = this.$utils.sortByKeyDesc(data, 'createTime')
          this.scollToTop()
        })
      } else if (type === 'hujiao') {
        this.showMeeting = false
        // 呼叫
        this.communicationRecordFind(
          {
            type: 'INVITE_INTO_ROOM',
          },
          (data) => {
            console.log(data)
            this.messageContent = data
            this.scollToTop()
          }
        )
      } else if (type === 'yuyue') {
        this.showMeeting = true
        this.messageContent = ''
        this.getMeetingRecordForAppointment()
        this.scollToTop()
      } else if (type === 'shenqing') {
        this.showMeeting = false
        // 申请
        this.communicationRecordFind(
          {
            type: 'ADD_CONTACTS',
          },
          (data) => {
            this.messageContent = data
            this.scollToTop()
          }
        )
      } else if (type === 'chuanshu') {
        this.showMeeting = false
        this.fileRecordFind(
          {
            type: 'PUSH_USER_ACCOUNT_FILE'
          },
          (data) => {
            // 添加一个排序时间createTime
            const list = data.map(res => Object.assign({}, res, {
              createTime: res.pushTime
            }))
            this.messageContent = list
            this.scollToTop()
          }
        )
      } else {
        this.showMeeting = false
        // 系统
        this.messageFindMy({}, (data) => {
          this.messageContent = this.$utils.sortByKeyDesc(data, 'createTime')
          this.scollToTop()
        })
      }
    },
    // 消息弹框显示完成触发
    getShowMessage() {
      this.getForceUpdate()
      this.showMessage = true
      this.isAllLoaded = false
      this.messageType = 'ALL'
      this.messageReadMessage(null, () => {
        this.getAllMessage((data) => {
          this.messageContent = this.$utils.sortByKeyDesc(data, 'createTime')
          this.isAllLoaded = true
        })
      })
    },
    getForceUpdate() {
      this.$utils.setLocalStorage('ShowMessage' + this.userInfo.accountId, '')
      this.$forceUpdate();
    },
    // afterEnter() {
    //   this.isAllLoaded = false
    //   this.messageType = 'ALL'
    //   this.messageReadMessage(null, () => {
    //     this.getAllMessage((data) => {
    //       this.messageContent = this.$utils.sortByKeyDesc(data, 'createTime')
    //       this.isAllLoaded = true
    //     })
    //   })
    // },
    // 合并消息数据
    getAllMessage(callback) {
      this.messageFindMy({}, (data) => {
        this.communicationRecordFind({}, (list) => {
          this.fileRecordFind({}, (file) => {
            const messageContent = [...data, ...list, ...file]
            callback && callback(messageContent)
          })
        })
      })
    },
    // 消息已读
    messageReadMessage(messageId, callback) {
      const params = {}
      if (messageId) {
        params.messageId = messageId
      } else {
        params.readAll = 'YES'
      }
      this.$ajax({
        url: this.$api.messageReadMessage,
        data: params,
        showLoading: true,
      }).then(() => {
        callback && callback()
      })
    },
    // 查询系统消息
    messageFindMy(params = {}, callback) {
      const newParams = {
        startCreateTime: new Date().getTime() - 7 * 24 * 60 * 60 * 1000,
        endCreateTime: new Date().getTime(),
      }
      Object.assign(newParams, params)
      this.$ajax({
        url: this.$api.messageFindMy,
        data: newParams,
        showLoading: true,
      }).then((data) => {
        callback && callback(data || [])
      })
    },
    // 查询添加联系人或会议邀请消息
    communicationRecordFind(params = {}, callback) {
      const newParams = {
        startCreateTime: new Date().getTime() - 7 * 24 * 60 * 60 * 1000,
        endCreateTime: new Date().getTime(),
      }
      Object.assign(newParams, params)
      this.$ajax({
        url: this.$api.communicationRecordFind,
        data: newParams,
        showLoading: true,
      }).then((data) => {
        callback && callback(data || [])
      })
    },
    // 查询文件输入
    fileRecordFind(params = {}, callback) {
      const newParams = {
        startCreateTime: new Date().getTime() - 7 * 24 * 60 * 60 * 1000,
        endCreateTime: new Date().getTime(),
      }
      Object.assign(newParams, params)
      this.$ajax({
        url: this.$api.getPushFileRecord,
        data: newParams,
        showLoading: true,
      }).then((data) => {
        // 添加一个排序时间createTime
        const list = data.map(res => Object.assign({}, res, {
          createTime: res.pushTime
        }))
        callback && callback(list || [])
      })
    },
    // 获取当前自己参与的预约会议记录
    getMeetingRecord(params = {}, callback) {
      const newParams = {
        startCreateTime: new Date().getTime() - 7 * 24 * 60 * 60 * 1000,
        endCreateTime: new Date().getTime(),
      }
      Object.assign(newParams, params)
      this.$ajax({
        url: this.$api.getMeetingRecordForAppointment,
        data: newParams,
        showLoading: true,
      }).then((data) => {
        callback && callback(data || [])
      })
    },
    // 选择页数
    currentChange(page) {
      this.pageNumber = page
      this.cloudRecordGetRecords()
    },
    // 删除云录制提示
    delRecord(item) {
      this.$utils
        .confirm({
          content: this.$t('deleteTips'),
        })
        .then(() => {
          this.cloudRecordDelete(item)
        })
        .catch(() => {})
    },
    // 删除云录制
    cloudRecordDelete(item) {
      this.$ajax({
        url: this.$api.cloudRecordDelete,
        data: {
          record_list: [item.recordId],
        },
      }).then(() => {
        this.$utils.showMsg(this.$t('deleteSuccess'))
        this.pageNumber = 1
        this.cloudRecordGetRecords()
      })
    },
    // 下载云录制
    downloadRecord(item) {
      if (!item.recordFiles || !item.recordFiles.length) return
      item.recordFiles.forEach((item) => {
        window.open(item.url)
      })
    },
    // 打开云录制
    openCloudRecord() {
      this.cloudRecordGetRecords(() => {
        this.showCloudRecord = true
      })
    },
    // 预览云录制
    cloudRecordRead(item) {
      this.showcloudRecordRead = true
      this.cloudRecordReadUrl = item.recordFiles[0].url
      console.log(this.cloudRecordReadUrl)
    },
    // 查询云录制列表
    cloudRecordGetRecords(cb) {
      this.$ajax({
        url: this.$api.cloudRecordGetRecords,
        data: {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        },
      }).then((data) => {
        const { totalElements, content } = data
        content.forEach((item) => {
          let mins = Math.floor(item.recordTime / 60)
          mins = mins > 9 ? mins : '0' + mins
          item.recordTime = `${mins}:${Math.floor(item.recordTime % 60)}`
        })
        this.totalElements = totalElements
        this.content = content
        if (cb) cb()
      })
    },
    // 上传头像
    headImgSuccess(res) {
      console.log(res)
      const headImg = res.data.fileHost + '/' + res.data.objectName
      this.$ajax({
        url: this.$api.userAccountUpdateMyHeadImg,
        data: {
          headImg: headImg,
        },
      }).then(() => {
        const user = this.$utils.getSessionStorage('user')
        user.headImg = headImg
        this.$store.commit('updateUser', user)
        this.$utils.showMsg(this.$t('updatedHeadImg'))
      })
    },
    // 打开设备弹框
    openMenuEquiment() {
      const token = this.$utils.getSessionStorage('token')
      const lang = this.$utils.getLocalStorage('lang') || 'zh'
      let language = 'English'
      if (lang === 'zh') {
        language = 'Chinese'
      } else if (lang === 'ja') {
        language = 'Japanese'
      }
      this.$ajax({
        url: this.$api.getCompanyConfig,
        data: {
          subject_token: token,
        },
      }).then((data) => {
        let manageHost = this.$path.manageHost
        if (data.company && data.company.beCustom === 'YES') {
          manageHost = data.host + '/'
        }
        this.showMenuEquimentSrc = `${manageHost}pcEquipmentManage?token%3D${token}%7Clanguage%3D${language}`
        this.showMenuEquiment = true
        console.log(this.showMenuEquimentSrc)
      })
    },
    // 查询用户详情
    userAccountFindInfo() {
      this.$ajax({
        url: this.$api.userAccountFindInfo,
        data: {
          accountId: this.user.accountId,
        },
      }).then((data) => {
        this.userInfo = data
      })
    },
    // 折叠菜单
    updateIsFold() {
      this.$store.commit('updateIsFold')
    },
    closeNickName() {
      this.setNicknameVisible = false
    },
    confirmNickName() {
      if (!this.nickNameDefault) {
        this.$utils.showMsg(this.$t("nicknameNoNull"), "warning");
        return
      }
      this.$ajax({
        url: this.$api.userAccountSetNickname,
        data: {
          nickname: this.nickNameDefault,
        },
      }).then(() => {
        this.userAccountFindInfo()
        this.$parent.sendGroupMsg({
          type: "UPDATE_NICKNAME",
        });
        this.$utils.showMsg(this.$t("editNicknameSuccess"));
        this.setNicknameVisible = false
      });
    },
    // 设置昵称
    openNickname() {
      this.setNicknameVisible = true
      this.nickNameDefault = this.userInfo.nickname
      console.log(this.userInfo)
    },
    // 设置头像
    openHeadimg() {
      this.setHeadImgVisible = true
      this.fileHeadImg = ""
      this.fileHeadImgArr = ""
    },
    // 关闭头像设置
    closesetHeadImg() {
      this.setHeadImgVisible = false
      this.fileHeadImg = ""
      this.fileHeadImgArr = ""
    },
    confirmSetHeadImg() {
      if (!this.fileHeadImg) {
        this.$utils.showMsg(this.$t("selectOnePicFalse"), "warning");
        return
      }
      if (this.fileHeadImgArr) {
        this.headImgSuccess(this.fileHeadImgArr)
      }
      this.setHeadImgVisible = false
    },
    // 选择头像
    changeHeagImg(response, file, fileList) {
      console.log(file.file,)
      if (file.size / (1024 * 1024) > 1) {
        this.$utils.showMsg(this.$t("selectOnePicSize"), "warning");
        return
      }
      this.fileHeadImgArr = response
      this.fileHeadImg = file.name
    },
    async beforeUploadImg(file) {
      const resAvatarBlob = await imageConversion.compressAccurately(file, 100);
      const resAvatarFile = new window.File(
        [resAvatarBlob],
        file.name,
        { type: file.type },
      );

      resAvatarFile.uid = file.uid
      return resAvatarFile;
    },
    // 打开麦克风设置
    openSetDevices() {
      this.setDialogDevices = true
    },
    closeSetDevices() {
      this.setDialogDevices = false
    },
    // 打开相机和麦克风设置
    openSetDialog() {
      this.setDialogVisible = true
      this.currentCamIdCopy = this.currentCamId // 摄像头
      this.currentMicIdCopy = this.currentMicId // 麦克风
    },
    // 关闭相机和麦克风设置
    closeSetDialog() {
      this.setDialogVisible = false
    },
    // 确认相机和麦克风设置
    confirmSetDialog() {
      this.currentCamId = this.currentCamIdCopy
      this.currentMicId = this.currentMicIdCopy
      this.setDialogVisible = false
      this.$emit('confirmSet', this.currentCamId, this.currentMicId)
    },
    // 关闭服务支持弹框
    closeHelpDialog() {
      this.helpDialogVisible = false
    },
    openServiceSupport() {
      this.helpDialogVisible = true
    },
    // app下载
    getfileDownload() {
      const that = this
      that.$ajax({
        url: this.$api.checkForUpdat,
        data: {
          osType: "APK",
          category: ""
        },
      }).then(data => {
        const { downloadUrl, title } = data.master
        var a = document.createElement('a');
        a.href = downloadUrl
        a.download = title;
        a.click()
      })
    },
    // 系统介绍
    getIntroduction() {
      const downloadUrl = "https://tmvapackage.oss-cn-hangzhou.aliyuncs.com/Document/" + this.OEM_NAME + "/Specification.pdf"
      const a = document.createElement('a')
      a.href = downloadUrl
      a.target = "_blank";
      a.click();
    },
    // 使用说明
    getInstructions() {
      const downloadUrl = "https://tmvapackage.oss-cn-hangzhou.aliyuncs.com/Document/" + this.OEM_NAME + "/Manual.pdf"
      const a = document.createElement('a')
      a.href = downloadUrl
      a.target = "_blank";
      a.click();
    },
    // 加载麦克风、扬声器和摄像头设备列表
    getMediaDevices() {
      if (this.rtcEngine === 'HST') {
        this.hstRtcEngine
          .getMediaDevices()
          .then((data) => {
            this.devicesList = data
            console.log(data)
            const { camDevs, micDevs, spkDevs } = data
            // 摄像头
            this.camDevs = camDevs.map((item) => ({
              value: item.devId,
              label: item.devName,
            }))
            if (this.camDevs.length) {
              const {value} = this.camDevs[0]
              this.currentCamId = value
              this.currentCamIdCopy = value
            }
            // 麦克风
            this.micDevs = micDevs.map((item) => ({
              value: item.devId,
              label: item.devName,
            }))
            if (this.micDevs.length) {
              const {value} = this.micDevs[0]
              this.currentMicId = value
              this.currentMicIdCopy = value
            }
            // 扬声器
            this.spkDevs = spkDevs.map((item) => ({
              value: item.devId,
              label: item.devName,
            }))
            if (this.spkDevs.length) {
              const {value} = this.spkDevs[0]
              this.currentSpkId = value
              this.currentSpkIdCopy = value
            }
            this.$emit('confirmSet', this.currentCamId, this.currentMicId)
          })
          .catch(() => {
          })
      } else if (this.rtcEngine === 'AGORA') {
        AgoraRTC.getDevices().then(res => {
          console.log('getDevices', res)
          this.devicesList = res
          this.camDevs = this.getFormatDeviceData('videoinput')
          if (this.camDevs.length) {
            const { value } = this.camDevs[0]
            this.currentCamId = value
            this.currentCamIdCopy = value
          }
          this.micDevs = this.getFormatDeviceData('audioinput')
          if (this.micDevs.length) {
            const { value } = this.micDevs[0]
            this.currentMicId = value
            this.currentMicIdCopy = value
          }
          this.spkDevs = this.getFormatDeviceData('audiooutput')
          if (this.spkDevs.length) {
            const { value } = this.spkDevs[0]
            this.currentSpkId = value
            this.currentSpkIdCopy = value
          }
          this.$emit('confirmSet', this.currentCamId, this.currentMicId)
        })
      }
    },

    getFormatDeviceData(type) {
      const data = this.devicesList.filter(item => item.kind === type)
      return data.map(item => ({
        value: item.deviceId,
        label: item.label,
      }))
    },

    // 获取当前自己参与的预约会议记录
    getMeetingRecordForAppointment() {
      this.$ajax({
        url: this.$api.getMeetingRecordForAppointment,
      }).then((data) => {
        if (data && data.length) {
          this.meetingDataList = data.filter((item) => {
            return item.appointmentTime < new Date().getTime()
          })
          this.meetingDataList = this.$utils.sortByKeyDesc(this.meetingDataList, 'appointmentTime')
        }
      })
    },
    // 获取文件推送记录
    getPushFileRecord() {
      this.$ajax({
        url: this.$api.getPushFileRecord,
      }).then((data) => {
        this.uploadFileList = data
      })
    },
    // 取消文件推送信息 撤销
    cancelPushFile(fID) {
      this.$ajax({
        url: this.$api.cancelPushFile,
        data: {
          pushFileId: fID
        }
      }).then((data) => {
        console.log(data)
        this.getPushFileRecord()
        const index = this.messageContent.findIndex(
          (item) => item.pushFileRecordId === fID
        );
        this.$set(this.messageContent, index, {
          ...this.messageContent[index],
          isBackOut: "YES"
        });
      })
    },
    // 预览
    getKkFileViewForPushFile(fID) {
      const that = this
      that.progressFlag = true
      that.progressPercent = 0
      that.$noLoading({
        url: that.$api.getKkFileViewForPushFile,
        data: {
          pushFileId: fID
        },
      }).then((data) => {
        console.log(data)
        that.getPushFileRecord()
        const index = that.messageContent.findIndex(
          (item) => item.pushFileRecordId === fID
        );
        that.$set(that.messageContent, index, {
          ...that.messageContent[index],
          isRead: "YES"
        });
        // https://tmvafiles.tmva-cn.com 沙盒
        // https://tmvafiles.team-view.cn 测试
        // https://tmvafiles.ar-office.com 正式
        const imgOs = data.fileUrl.replace(process.env.VUE_APP_FILEDOWN_HOST, 'fliedown')
        const xhr = new XMLHttpRequest();
        xhr.open('get', imgOs, true);
        xhr.responseType = 'blob';
        xhr.onprogress = updateProgress => {
          if (updateProgress.lengthComputable) {
            that.progressPercent = parseInt((updateProgress.loaded / updateProgress.total) * 100);
          }
          if (that.progressPercent === 100) {
            setTimeout(() => {
              that.progressFlag = false
            }, 1000)
          }
        }
        xhr.send();
        that.uploadFileData = data
        that.showFileName = data.fileName
        that.uploadFileShow = data.kkFileViewUrl
        that.showReadFile = true
      })
    },

    uploadLink(item) {
      // https://tmvafiles.tmva-cn.com 沙盒
      // https://tmvafiles.team-view.cn 测试
      // https://tmvafiles.ar-office.com 正式
      const imgOs = item.fileUrl.replace(process.env.VUE_APP_FILEDOWN_HOST, 'fliedown')
      var x = new XMLHttpRequest();
      x.open("GET", imgOs, true);
      x.responseType = 'blob';
      x.onload = function(e) {
        var url = window.URL.createObjectURL(x.response)
        var a = document.createElement('a');
        a.href = url
        a.download = item.fileName;
        a.click()
      }
      x.send();
    },
    // 删除文件推送
    delPushFileRecord(fID) {
      this.$ajax({
        url: this.$api.delPushFileRecord,
        data: {
          pushFileId: fID
        }
      }).then((data) => {
        // console.log(data)
        this.getPushFileRecord()
        const index = this.messageContent.findIndex(
          (item) => item.pushFileRecordId === fID
        );
        if (index >= 0) {
          this.messageContent.splice(index, 1);
        }
      })
    },
    // 退出登录
    logout() {
      this.$emit('logout')
    },
  },
}
</script>

<style lang="scss">
.nickNameBox{
  p {
    margin-top: 6px;
    height: 14px;
    font-size: 10px;
    font-weight: 400;
    color: #6E818B;
    line-height: 14px;
  }
}
.logout{
  position: relative;
  display: inline-block;
  .logoutdown{
    display: block;
    position: absolute;
    background-color: #fff;
    min-width: 110px;
    z-index: 1;
    top: 70px;
    right: 10px;
    text-align: center;
    line-height: 30px;
    border-radius: 3px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    cursor: pointer;
     p {
        padding: 5px 16px;
      }
      &:hover {
        background: #E9F4FE;
        border-radius: 3px;
      }
  }
}
.topdropdown{
  position: relative;
  display: inline-block;
  .topdropdowncontent{
    display: block;
    position: absolute;
    background-color: #fff;
    min-width: 110px;
    text-align: left;
    z-index: 1;
    top: 80px;
    left: 0px;
    z-index: 11;
    line-height: 30px;
    border-radius: 8px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    &::after, &::before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &::after {
      border-color: rgba(213, 213, 213, 0);
      border-bottom-color: #fff;
      border-width: 10px;
      margin-left: -10px;
    }
    &::before {
      border-color: rgba(163, 163, 163, 0);
      border-bottom-color: rgb(181, 181, 181);
      border-width: 10px;
      margin-left: -10px;
    }

    .dropdownItem {
      cursor: pointer;
      span {
          padding: 5px 16px;
      }
      &:hover {
        background: #E9F4FE;
        border-radius: 8px;
      }
    }
  }
}
.AudioSetDialog{
  .el-dialog{
    width: 450px;
  }
}
.cameraDialog{
  .el-dialog{
    width: 600px;
  }
}
.headImgDialog {
  .el-dialog{
    width: 600px;
  }
}
.serviceSupportDialog{
  .el-dialog{
    width: 690px;
  }
}
.radioSelectBox{
  text-align: center;
  padding: 20px;
  .is-active {
    border: 1px solid #0B81E0;
  }
  .el-radio-button__inner{
    padding: 5px !important;
    .selectIcon > img{
      width: 80px;
      height: 80px;
    }
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner{
    background:#FFFFFF;
    border: 1px solid #DCDFE6;
    box-shadow: 0px 0 0 0 #DCDFE6 !important;
  }
}
.currentPagination {
  .active{
    color: #0b81e0 !important;
  }
}
.btnKptrr {
  position: absolute;
  top: -20px;
  right: 0px;
}
.noDataMkl {
  font-size: 20px;
  text-align: center;
  margin-top: 160px;
  color: rgb(153, 153, 153);
}
.messageContent_1 {
  width: 600px;
  height: 550px;
  position: relative;

  .sysMessageLists {
    width: 100%;
    .list{
      padding: 20px 20px 15px 20px ;
    }
    .list2{
     padding: 20px 20px 10px 20px ;
    }
    .list, .list2{
      min-height: 84px;
      background: #F1F1F5;
      border-radius: 10px;
      color: #6e818b;
      font-size: 14px;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      margin-top: 10px;
      box-shadow: 0px 1px 4px 0px rgba(54, 63, 72, 0.35);

      &:first-child {
        margin-top: 0;
      }
    }

    .personBox{
      position: absolute;
      top: -25px;
      right: 0px;
    }
    .txtBord{
      font-size: 14px;
      font-weight: 600;
      color: #000000;
    }
    .txtColor{
      color: #55A2E5;
    }
   .txtHidden{
      white-space: nowrap;
      text-overflow:ellipsis;
      overflow:hidden;
    }
    .personright{
      text-align: right;
    }
    .xtTitle{
      padding-top: 15px;
      height: 22px;
      font-size: 16px;
      font-weight: 600;
      color: #000000;
      line-height: 22px;
    }
    .xtContent{
      font-size: 14px;
    }
    .xtTimes {
      position: absolute;
      top: -18px;
      right: 0px;
    }
    .btnYuyue{
      position: absolute;
      top: -25px;
      right: 0px;
      .el-button--small{
        font-size: 14px;
        padding: 5px 21px;
      }
    }
    .roomUniqueNoID{
      white-space: nowrap;
      text-overflow:ellipsis;
      overflow:hidden;
    }
    .fileBtnRed{
      position: absolute;
      top: -25px;
      right: 0px;
      .el-button--small{
        font-size: 14px;
        padding: 5px 21px;
        background: #0B81E0;
        border-color:#0B81E0;
      }
    }
    .fileBtnRedD{
      position: absolute;
      top: -25px;
      right: 90px;
      .el-button--small{
        font-size: 14px;
        padding: 5px 21px;
        background: #0B81E0;
        border-color:#0B81E0;
      }
    }
    .fileBtndel{
      position: absolute;
      top: -25px;
      right: 0px;
      .el-button--small{
        font-size: 14px;
        padding: 5px 21px;
        background: #FF4D4F;
        border-color:#FF4D4F;
      }
    }
    .fileBtnBack{
      position: absolute;
      top: -25px;
      right: 0px;
      .el-button--small{
        font-size: 14px;
        padding: 5px 21px;
        background: #FFA919;
        border-color:#FFA919;
      }
    }
    .fileBtnRedTxt{
      position: absolute;
      top: -25px;
      right: 90px;
      color: #B9B9B9;
      font-size: 14px;
    }
    .fileBtnText{
      position: absolute;
      top: -25px;
      right: 0px;
      color: #B9B9B9;
      font-size: 14px;
    }

    .listInner {
      padding-top: 10px;
      .el-button--primary{
        background: #0B81E0;
        border-color:#0B81E0;
      }
    }
    .listbottom{
      padding-top: -8px;
    }
    .listInnerFile{
      padding-top: 40px;
      .el-button--primary{
        background: #0B81E0;
        border-color:#0B81E0;
      }
    }
    .fileNameTitle{
      font-weight: 600px;
      font-size: 16px;
      padding-top: 5px;
      color: #000000;
    }
    .fileUserName{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    h2 {
      height: 16px;
      line-height: 16px;
      font-size: 16px;
      font-weight: 600;
      color: #000000;
      border-left: 4px solid #72DE00;
      padding-left: 10px;
      vertical-align:middle;
      .titleBoxName {
        float: left;
        font-size: 16px;
        vertical-align:middle;
      }
      .titleBoxPic {
        width: 16px;
        height: 16px;
        float: left;
        margin-left: 5px;
        // border: 1px dashed #ccc;
        vertical-align:middle;
      }
    }
  }

  .messageContent_inner {
    width: 567px;
    position: absolute;
    left: 0;
    top: 50px;
    bottom: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 6px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: #cccccc;
    }
  }

  .messageContent_title {
    width: 587px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: #fff;
    .applyText{
      font-size: 12px;
    }
    li {
      display: inline-block;
      min-width: 85px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #6e818b;
      color: #fff;
      font-size: 14px;
      margin: 0 1px;
      padding: 0 3px;

      &:hover {
        transition: all 0.3s;
        opacity: 0.8;
        cursor: pointer;
      }

      &.active {
        background: #353e47;
      }

      &:first-child {
        border-radius: 4px 0px 0px 4px;
      }

      &:last-child {
        border-radius: 0px 4px 4px 0px;
      }
      img,
      span {
        display: inline-block;
        vertical-align: middle;
      }

      img {
        margin-right: 5px;
      }
    }
  }
}
.messageContentFoot{
  margin-top: 10px;
  line-height: 20px;
  font-size: 13px;
  color: #aaa;
}
.messageCss{
  border-radius: 10px;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body{
    padding: 20px;
    border-radius: 10px;
  }
}

.baseDialog.equimentBoxKKK {
  .el-dialog__body {
    padding: 0;
    height: 100% !important;
  }
  .dialog-content {
    height: 100% !important;
  }
  .el-dialog__header {
    padding: 8px 15px;
  }
  .el-dialog__headerbtn {
    top: 15px;
  }
  .el-dialog {
    width: 80% !important;
    margin-top: 0 !important;
    height: 80%;
    top: 10%;
  }
}
.cloudRecordList {
  display: inline-block;
  width: 150px;
  height: 150px;
  margin: 10px;

  i {
    padding-right: 5px;
    cursor: pointer;
  }
  .cloudRecordOpt div:hover {
    color: #ccc;
    cursor: pointer;
  }

  .cloudRecordContent {
    width: 150px;
    height: 100px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
  }

  .time {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
  }
  .play{
    cursor: pointer;
    position: absolute;
    top: 30px;
    left: 60px;
    img{
      width: 30px;
    }
  }

  p {
    color: #8b8c99;
    font-size: 14px;
    margin-top: 8px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .cloudRecordOptEn{
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0 3px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    color: #fff;
    width: 150px;
    height: 34px;
    .deleteOptEn{
      margin-left: 5px;
    }
    .el-icon-download, .el-icon-delete{
      margin-right: -5px;
    }
  }

  .cloudRecordOpt {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0 10px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    height: 34px;
  }
}
.equimentIframe {
  width: 100%;
  height: 100%;
}
.headImgBj {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  // background: url('./../../assets/defaultHead.jpg') no-repeat center / cover;
  .addHeadImgTo{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: url('./../../assets/defaultHead.jpg') no-repeat center / cover;
  }
  .headLogo{
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
.topHeader {
  position: absolute;
  top: 0;
  right: 0;
  height: 70px;
  left: 300px;
  transition: all 0.3s;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;

  &.active {
    left: 0;
  }

  .rightOptions {
    position: absolute;
    top: 0;
    right: 0;
    height: 70px;
    display: flex;
    align-items: center;
    color: #85888c;

    .tool {
      background: url('./img/tool.png') no-repeat center center;
      background-size: 22px 22px;
    }

    .set {
      background: url('./img/set.png') no-repeat center center;
      background-size: 24px 24px;
    }

    .device {
      background: url('./img/device.png') no-repeat center center;
      background-size: 24px 24px;
    }
    .yun {
      background: url('./img/yun.png') no-repeat center center;
      background-size: 24px 24px;
    }

    .help {
      background: url('./img/help.png') no-repeat center center;
      background-size: 24px 24px;
    }

    p {
      margin-top: 5px;
    }

    .list {
      height: 70px;
      min-width: 90px;
      text-align: center;
      border-right: 1px solid #dcdfe6;
      cursor: pointer;
      transition: all 0.3s;
      padding: 0 10px;

      &:hover .icon {
        color: #fff;
      }

      .el-dropdown {
        width: 100%;
      }

      .el-dropdown-link {
        height: 70px;
      }

      &:hover,
      &.active {
        background: #525f6b;

        p {
          color: #fff;
        }

        .tool {
          background: url('./img/tool_active.png') no-repeat center center;
          background-size: 22px 22px;
        }

        .set {
          background: url('./img/set_active.png') no-repeat center center;
          background-size: 24px 24px;
        }
        .device {
          background: url('./img/device_active.png') no-repeat center center;
          background-size: 24px 24px;
        }
        .yun {
          background: url('./img/yun_active.png') no-repeat center center;
          background-size: 24px 24px;
        }

        .help {
          background: url('./img/help_active.png') no-repeat center center;
          background-size: 24px 24px;
        }
      }
    }

    .headFace {
      width: 300px;
      height: 70px;
      text-align: center;

      > div {
        display: inline-block;
        vertical-align: middle;
        max-width: 210px;
      }

      .el-dropdown-link {
        line-height: 70px;
        padding-right: 10px;
        cursor: pointer;
      }

      .el-avatar {
        margin-top: 15px;
        vertical-align: middle;
        margin-right: 15px;
      }

      .name {
        font-size: 15px;
        color: #25233a;
      }

      .group {
        color: #8b8c99;
        font-size: 12px;
        padding-top: 5px;
      }

      .userName {
        text-align: left;
        display: flex;
        align-items: center;

        > div {
          &:first-child {
            width: 180px;
          }
        }
      }
    }

    .icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-top: 13px;
      font-size: 22px;
    }
  }

  .leftMenu {
    display: inline-block;
    height: 70px;
    padding: 0 20px;
    cursor: pointer;
    position: relative;
    .foldactive{
      width: 20px;
      height: 20px;
    }
    // .floadTitle[data-title]{
    //   position: relative;
    //   &:hover::after{
    //     opacity: 1;
    //     transition: all 0.1s ease 0.5s;
    //     visibility: visible;
    //   }
    //   &::after{
    //     content: '';
    //     background-color: rgba(80, 79, 79, 0.8);
    //     color: #fff;
    //     font-size: 13px;
    //     position: absolute;
    //     padding: 4px 10px;
    //     left: 20px;
    //     bottom: -1.5em;
    //     border-radius: 4px;
    //     white-space: nowrap;
    //     box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    //     z-index: 99999;
    //     visibility: hidden;
    //     opacity: 0;
    //   }
    // }
    img {
      margin-top: 25px;
    }
  }

  .newMessage {
    display: block;
    width: 8px;
    height: 8px;
    background: #df2c2c;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    right: 10px;
  }
}

.serviceInner {
  height: 270px;
  padding-left: 40px;
  padding-bottom: 30px;

  > div {
    margin-top: 20px;
  }

  i {
    color: #0b81e0;
  }

  span {
    display: inline-block;
    text-align: right;
  }

  .el-input {
    width: 250px;
  }
}

.serviceBtn {
  text-align: center;
  border-top: 1px solid #dcdfe6;
  padding-top: 15px;
}

.setInner {
  padding-top: 20px;
  padding-left: 20px;
  .el-select {
    width: 300px;
  }
}
.setDevicesBox{
  padding-top: 10px;
  padding-left: 25px;
  padding-bottom: 25px;
  .el-row {
    margin-bottom: 10px;
    .el-col:first-child {
      text-align: left;
      font-size: 13px;
    }
    // &:last-child {
    //   margin-bottom: 0;
    // }
  }

  .el-select, .el-slider {
    width: 320px;
  }

}

.helpInnerBox{
  position: relative;
  padding: 20px 10px;
    &::after{
      content: '';
      display: block;
      clear: both;
    }
  .versionCode{
    position: absolute;
    top:40px;
    right:30px;
    font-size: 16px;
  }
  .helpInnerLeft{
    width: 110px;
    float: left;
    .imgBox {
      width: 100%;
    }
  }
  .helpInnerRight{
    line-height: 28px;
    margin-left: 30px;
    width: 480px;
    float: left;
    p:first-child {
      font-size: 16px;
      font-weight: 600;
    }
    p:nth-child(2){
       background: url('./img/helptel.png') no-repeat left center;
       padding-left: 25px;
    }
    p:nth-child(3){
       background: url('./img/helpemail.png') no-repeat left center;
       padding-left: 25px;
    }
    p:nth-child(4){
       background: url('./img/helpinter.png') no-repeat left center;
       padding-left: 25px;
    }
    p:nth-child(5){
       background: url('./img/helphouse.png') no-repeat left center;
       padding-left: 25px;
    }
  }
}
.helpInner {
  text-align: center;
  color: #242526;
  padding-bottom: 15px;

  .version {
    position: absolute;
    top: 70px;
    right: 0;
    transform: translateX(150%);
    color: #999;
  }

  .imgBox {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 15px;
    position: relative;
    height: 93px;
  }

  img {
    width: 85px;
  }

  p {
    line-height: 30px;
  }
}

.showcloudRecordRead{
  .el-dialog {
    border-radius: 6px;
  }
   .el-dialog__body {
    padding-top: 20px;
    height: 100% !important;
    border-radius: 6px ;
  }
  .dialog-content {
    height: 100% !important;
  }
  .el-dialog__header {
    background-color: #F0F0F0;
    border-radius: 6px 6px 0 0;
  }
}

.uploadFileBoxRead {
  .el-dialog {
    border-radius: 6px;
  }
   .el-dialog__body {
    padding: 0;
    height: 100% !important;
    border-radius: 6px ;
  }
  .dialog-content {
    height: 100% !important;
  }
  .el-dialog__header {
    padding: 0px;
  }
  .upload_heard{
    padding: 18px 15px;
    border-radius: 6px 6px 0 0;
    background-color: #F0F0F0;
  }
  .progress{
    z-index: 2000;
  }
  .uploadBtnBox{
    position: relative;
    float: right;
    margin-top: -8px;
    margin-right: 120px;
    .uploadBtnIcon{
      background: url('./img/uploadfile.png') no-repeat center center;
      width: 28px;
      height: 28px;
      cursor: pointer;
    }
    .uploadBtnLine{
      margin-top: 8px;
      .uploadMune{
        display: inline-block;
        background: url('./img/fileIco.png') no-repeat center center;
        width: 20px;
        height: 20px;
        vertical-align: middle;
         &::after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          clear: both;
        }
      }
      .el-icon-arrow-down{
        cursor: pointer;
      }
    }
  }
  .uploadLink{
    width: 100%;
    height: 850px;
  }

}

// 传输
.enterFiler {
  width: 100%;
  .fileBox{
    background-color: #F1F1F5;
    .coltype {
      height: 30px;
      height: 30px;
      font-size: 14px;
      font-weight: 600;
      color: #000000;
       font-size: 14px;
      border-left: 4px solid #72de00;
      padding-left: 5px;
    }
    .fileHandle{
      text-align: center;
      .txtBt{
        cursor: pointer;
        width: 40px;
        border-radius: 4px;
        padding: 5px 15px;
        border: 1px solid #0B81E0;
        background: #F3EAE7;
        margin-left: 5px;
        font-size: 12px;
        &:hover {
          background: #F5D8CE;
        }
      }
      .txtBtdel{
        cursor: pointer;
        width: 40px;
        border-radius: 4px;
        padding: 5px 15px;
        border: 1px solid #0B81E0;
        margin-left: 5px;
        color: #0B81E0;
        font-size: 12px;
        &:hover {
          background: #E5E5F7;
        }
      }
      .txtBtyulan{
        cursor: pointer;
        width: 40px;
        border-radius: 4px;
        padding: 5px 15px;
        border: 1px solid #0B81E0;
        background: #F3EAE7;
        margin-left: 5px;
        font-size: 12px;
        &:hover {
          background: #F5D8CE;
        }
      }
      .txt{
        width: 40px;
        border-radius: 4px;
        padding: 5px 15px;
        background: #D8D8EC;
        margin-left: 5px;
        font-size: 12px;
        color: #ffffff;
      }
    }
    .itemLine{
      font-size: 13px;
      color: #AAAAAA;
    }
    .fileNameName{
      width: 340px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow:ellipsis;
    }
  }

}

.headpicBox{
  margin-top: 10px;
  width: 95%;
  .el-form-item{
    margin-bottom: 10px !important;
  }
}
.headpicTxt{
  margin-left: 90px;
  height: 14px;
  font-size: 10px;
  font-weight: 400;
  color: #6E818B;
  line-height: 14px;
}

</style>
